@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply min-h-screen;
    @apply font-roboto;
  }

  #root {
    @apply min-h-screen;
  }
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002a4d;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7893a1;
}

.Toastify__progress-bar--info {
  background: #56c271 !important;
}
.Toastify__progress-bar--success {
  background: #56c271 !important;
}

.Toastify__progress-bar--error {
  background: #f44336 !important;
}

.Toastify__progress-bar--warn {
  background: #ff9800 !important;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  display: none !important;
}

#couponCarousel .carousel-dark .carousel-control-next-icon {
  margin-left: auto;
}

#couponCarousel .carousel-dark .carousel-control-prev-icon {
  margin-right: auto;
}

#couponCarousel .carousel-control-next,
#couponCarousel .carousel-control-prev {
  width: auto !important;
}

#discountCategorySelect div {
  border-width: 0;
}
